<template>
  <div :class="['toast', hide ? 'hide' : '']">
    {{ message }}
  </div>
</template>

<script>
export default {
  name: "toast",
  props: {
    message: {},
  },
  created() {
    setTimeout(() => {
      this.hide = true;
    }, 2000);
  },
  data() {
    return {
      hide: false,
    };
  },
};
</script>

<style scoped>
.toast {
  position: fixed;
  background-color: #fef0f0;
  color: #f56c6c;
  top: 100px;
  left: 50%;
  transform: translateX(-50%);
  border: 1px solid #f56c6c;
  padding: 8px 16px;
  min-width: 200px;
  margin: 0;
  box-sizing: border-box;
  border-radius: 4px;
  overflow: hidden;
  opacity: 1;
  display: flex;
  align-items: center;
  font-size: 18px;
  justify-content: center;
  z-index: 4;
  transition: opacity 0.2s;
}
.hide {
  opacity: 0;
}
</style>
