const staticGiftList = [
  {
    product_id: "51020",
    product_subject: "月卡計畫",
    product_subject_en: "Monthly Card",
  },
  {
    product_id: "51017",
    product_subject: "終身計畫",
    product_subject_en: "Permanent Card",
  },
  {
    product_id: "51007",
    product_subject: "成長基金1階",
    product_subject_en: "Growth Fund I",
  },
  {
    product_id: "51008",
    product_subject: "成長基金2階",
    product_subject_en: "Growth Fund II",
  },
  {
    product_id: "51009",
    product_subject: "成長基金3階",
    product_subject_en: "Growth Fund III",
  },
  {
    product_id: "51010",
    product_subject: "成長基金4階",
    product_subject_en: "Growth Fund IV",
  },
  {
    product_id: "51011",
    product_subject: "成長基金5階",
    product_subject_en: "Growth Fund V",
  },
  {
    product_id: "51012",
    product_subject: "成長基金6階",
    product_subject_en: "Growth Fund VI",
  },
  {
    product_id: "51013",
    product_subject: "成長基金7階",
    product_subject_en: "Growth Fund VII",
  },
  {
    product_id: "51014",
    product_subject: "成長基金8階",
    product_subject_en: "Growth Fund VIII",
  },
  {
    product_id: "51015",
    product_subject: "成長基金9階",
    product_subject_en: "Growth Fund IX",
  },
  {
    product_id: "51016",
    product_subject: "成長基金10階",
    product_subject_en: "Growth Fund X",
  },
  {
    product_id: "51018",
    product_subject: "調查官特權",
    product_subject_en: "Inspector Privilege",
    product_subject_kr: "조사관 특권",
  },
  {
    product_id: "1030001",
    product_subject: "高級合約",
    product_subject_en: "Standard Advanced Contract",
  },
  {
    product_id: "1030002",
    product_subject: "豪華合約",
    product_subject_en: "Deluxe Advanced Contract",
  },
  {
    product_id: "1030003",
    product_subject: "升級至高級合約-豪華版",
    product_subject_en: "Upgrade",
  },
  {
    product_id: "91002",
    product_subject: "每日特惠I",
    product_subject_en: "Daily Discount I",
  },
  {
    product_id: "91003",
    product_subject: "每日特惠II",
    product_subject_en: "Daily Discount II",
  },
  {
    product_id: "91004",
    product_subject: "每日特惠III",
    product_subject_en: "Daily Discount III",
  },
  {
    product_id: "91005",
    product_subject: "每日特惠組合周卡",
    product_subject_en: "Daily Discount - Weekly Pack",
  },
  {
    product_id: "51019",
    product_subject: "首充禮包",
    product_subject_en: "1st Top-up Pack",
  },
  {
    product_id: "51021",
    product_subject: "限時特惠",
    product_subject_en: "Special Offer",
  },
  {
    product_id: "51022",
    product_subject: "月卡（續費）",
    product_subject_en: "Monthly Plan (Renew)",
  },
  {
    product_id: "105001",
    product_subject: "艾可特惠禮包Ⅰ",
    product_subject_en: "Echo Discount Pack I",
  },
  {
    product_id: "105002",
    product_subject: "艾可特惠禮包Ⅱ",
    product_subject_en: "Echo Discount Pack II",
  },
  {
    product_id: "105003",
    product_subject: "艾可特惠禮包Ⅲ",
    product_subject_en: "Echo Discount Pack III",
  },
  {
    product_id: "105004",
    product_subject: "艾可特惠禮包Ⅳ",
    product_subject_en: "Echo Discount Pack IV",
  },
  {
    product_id: "105005",
    product_subject: "艾可特惠禮包Ⅴ",
    product_subject_en: "Echo Discount Pack V",
  },
  {
    product_id: "105006",
    product_subject: "艾可特惠禮包Ⅵ",
    product_subject_en: "Echo Discount Pack VI",
  },
  {
    product_id: "1011002",
    product_subject: "小白的心意10級禮包",
    product_subject_en: "White's Pick - Lv.10 Pack",
  },
  {
    product_id: "1011001",
    product_subject: "小白的心意10級禮包",
    product_subject_en: "White's Pick - Lv.10 Pack",
  },
  {
    product_id: "1012002",
    product_subject: "小白的心意15級禮包",
    product_subject_en: "White's Pick - Lv.15 Pack",
  },
  {
    product_id: "1012001",
    product_subject: "小白的心意15級禮包",
    product_subject_en: "White's Pick - Lv.15 Pack",
  },
  {
    product_id: "1013002",
    product_subject: "小白的心意20級禮包",
    product_subject_en: "White's Pick - Lv.20 Pack",
  },
  {
    product_id: "1013001",
    product_subject: "小白的心意20級禮包",
    product_subject_en: "White's Pick - Lv.20 Pack",
  },
  {
    product_id: "1014002",
    product_subject: "小白的心意25級禮包",
    product_subject_en: "White's Pick - Lv.25 Pack",
  },
  {
    product_id: "1014001",
    product_subject: "小白的心意25級禮包",
    product_subject_en: "White's Pick - Lv.25 Pack",
  },
  {
    product_id: "1015002",
    product_subject: "小白的心意30級禮包",
    product_subject_en: "White's Pick - Lv.30 Pack",
  },
  {
    product_id: "1015001",
    product_subject: "小白的心意30級禮包",
    product_subject_en: "White's Pick - Lv.30 Pack",
  },
  {
    product_id: "1016002",
    product_subject: "小白的心意35級禮包",
    product_subject_en: "White's Pick - Lv.35 Pack",
  },
  {
    product_id: "1016001",
    product_subject: "小白的心意35級禮包",
    product_subject_en: "White's Pick - Lv.35 Pack",
  },
  {
    product_id: "1017002",
    product_subject: "小白的心意40級禮包",
    product_subject_en: "White's Pick - Lv.40 Pack",
  },
  {
    product_id: "1017001",
    product_subject: "小白的心意40級禮包",
    product_subject_en: "White's Pick - Lv.40 Pack",
  },
  {
    product_id: "1018002",
    product_subject: "小白的心意45級禮包",
    product_subject_en: "White's Pick - Lv.45 Pack",
  },
  {
    product_id: "1018001",
    product_subject: "小白的心意45級禮包",
    product_subject_en: "White's Pick - Lv.45 Pack",
  },
  {
    product_id: "1019002",
    product_subject: "小白的心意50級禮包",
    product_subject_en: "White's Pick - Lv.50 Pack",
  },
  {
    product_id: "1019001",
    product_subject: "小白的心意50級禮包",
    product_subject_en: "White's Pick - Lv.50 Pack",
  },
  {
    product_id: "3030002",
    product_subject: "礦物原石補給I",
    product_subject_en: "礦物原石補給I",
  },
  {
    product_id: "3030003",
    product_subject: "礦物原石補給II",
    product_subject_en: "礦物原石補給II",
  },
  {
    product_id: "3030004",
    product_subject: "礦物原石補給III",
    product_subject_en: "礦物原石補給III",
  },
  {
    product_id: "3030005",
    product_subject: "礦物原石補給IV",
    product_subject_en: "礦物原石補給IV",
  },
  // 使用默认图标
  {
    product_id: "1060001",
    product_subject: "迴響通行證",
    product_subject_en: "Nightmare Pass",
    icon: "none",
  },
  {
    product_id: "109003",
    product_subject: "祕寶迴廊1",
    product_subject_en: "Treasure Chain I",
    icon: "none",
  },
  {
    product_id: "109006",
    product_subject: "祕寶迴廊2",
    product_subject_en: "Treasure Chain II",
    icon: "none",
  },
  {
    product_id: "109009",
    product_subject: "祕寶迴廊3",
    product_subject_en: "Treasure Chain III",
    icon: "none",
  },
  // 只改名字
  {
    product_id: "90001",
    product_subject: "60貓眼石",
    product_subject_en: "60 Opals",
    icon: "3000002",
  },
  {
    product_id: "90002",
    product_subject: "300貓眼石",
    product_subject_en: "300 Opals",
    icon: "3000002",
  },
  {
    product_id: "90003",
    product_subject: "680貓眼石",
    product_subject_en: "680 Opals",
    icon: "3000002",
  },
  {
    product_id: "90004",
    product_subject: "1280貓眼石",
    product_subject_en: "1280 Opals",
    icon: "3000002",
  },
  {
    product_id: "90005",
    product_subject: "3280貓眼石",
    product_subject_en: "3280 Opals",
    icon: "3000002",
  },
  {
    product_id: "90006",
    product_subject: "6480貓眼石",
    product_subject_en: "6480 Opals",
    icon: "3000002",
  },
  {
    product_id: "3030002",
    product_subject: "礦物原石補給I",
    product_subject_en: "礦物原石補給I",
    icon: "",
  },
  {
    product_id: "3030003",
    product_subject: "礦物原石補給II",
    product_subject_en: "礦物原石補給II",
    icon: "",
  },
  {
    product_id: "3030004",
    product_subject: "礦物原石補給III",
    product_subject_en: "礦物原石補給III",
    icon: "",
  },
  {
    product_id: "3030005",
    product_subject: "礦物原石補給IV",
    product_subject_en: "礦物原石補給IV",
    icon: "",
  },
];

export default staticGiftList;
